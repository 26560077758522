import React, { useState, useRef, useEffect , useCallback } from 'react';
import AddClientLocation from "./AddClientLocation";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from 'axios';
import { API_URL } from "../constants";
import { FaEdit } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { fr } from 'date-fns/locale';
import html2canvas from 'html2canvas';
import moment from 'moment';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import differenceInDays from 'date-fns/differenceInDays';
import logoAiga from "./logoAiga.png";
import { addDays } from "date-fns";

registerLocale('fr', fr);

export default function DetailsCompteur(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;

  const location = useLocation();
 const [motor, setMotor] = useState(location.state?.boitier || {});
  const [isEditing, setIsEditing] = useState(false);
  const [newMotorName, setNewMotorName] = useState(motor.name);

  const [startDate, setStartDate] = useState(addDays(new Date(),0));
  const [endDate, setEndDate] = useState(new Date());
  const [granularity, setGranularity] = useState('day');
  const [availableGranularities, setAvailableGranularities] = useState(['day']);
  const [data] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [debitSeuil, setDebitSeuil] = useState('');
  const [alertes, setAlertes] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const chartContainerRef = useRef();
  const secondChartContainerRef = useRef();

  const [impulseVolume, setImpulseVolume] = useState(() => {
    // Récupérer le volume par impulsion depuis le localStorage ou définir une valeur par défaut
    const storedVolume = localStorage.getItem(`impulseVolume_${motor.id}`);
    return storedVolume ? parseFloat(storedVolume) : undefined; // Valeur par défaut si aucune donnée n'est trouvée
  });

    const handleVolumeChange = (e) => {
      const newVolume = parseFloat(e.target.value);
      setImpulseVolume(newVolume);
      // Stocker la nouvelle valeur dans le localStorage
      localStorage.setItem(`impulseVolume_${motor.id}`, newVolume);
    };
    

  useEffect(() => {
    if (data.length > 0 && debitSeuil) {
      const filteredAlertes = data
        .filter(item => item.Consommation > debitSeuil)
        .filter(item => {
          const itemDate = new Date(item.name);
          return itemDate >= startDate && itemDate <= endDate;
        })
        .map(item => ({
          date: item.name,
          debit: item.Consommation
        }));
      setAlertes(filteredAlertes);
    }
  }, [data, debitSeuil, startDate, endDate]);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Mettre à jour si la largeur d'écran est inférieure à 768px (taille mobile)
    };

    // Ajouter un écouteur d'événement pour le redimensionnement
    window.addEventListener('resize', handleResize);

    // Appeler handleResize au montage du composant pour obtenir la taille actuelle
    handleResize();

    // Nettoyer l'écouteur d'événement lorsque le composant est démonté
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const daysDiff = differenceInDays(endDate, startDate) + 1;

    let granularities = [];

    if (daysDiff < 2) {
      granularities.push('day');
    }

    if (daysDiff >= 7) {
      granularities.push('week');
    }

    const monthsDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
    if (monthsDiff >= 1) {
      granularities.push('month');
    }

    if (daysDiff >= 365) {
      granularities.push('year');
    }

    setAvailableGranularities(granularities);

    if (!granularities.includes(granularity)) {
      setGranularity(granularities[0]);
    }
  }, [startDate, endDate, granularity]);

  useEffect(() => {
    if (data.length > 0 && debitSeuil) {
      const newAlertes = data.filter(item => item.Consommation > debitSeuil).map(item => ({
        date: item.name,
        debit: item.Consommation
      }));
      setAlertes(newAlertes);
    }
  }, [data, debitSeuil]);

  useEffect(() => {
    // Generate hourly data for the second chart
    const generateHourlyData = () => {
      const hourlyGeneratedData = [];
      for (let hour = 0; hour < 24; hour++) {
        hourlyGeneratedData.push({
          name: `${String(hour).padStart(2, '0')}:00`,
          Debit: Math.floor(Math.random() * 50) + 10
        });
      }
    };
    generateHourlyData();
  }, []);

  const fetchData = useCallback(async (debut, fin) => {
    try {
      moment.locale('fr-FR');  // Définir la langue en français

      const newEndDate = moment(fin).add(1, 'day').format('YYYY-MM-DD'); // On ajoute un jour pour inclure la journée complète
      const response = await axios.get(`${API_URL}/?d1=${debut}&d2=${newEndDate}&id=${motor.id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        },
      });

      const responseData = response.data.data;

      // Récupérer le volume par impulsion
      const impulseVolumeStr = localStorage.getItem(`impulseVolume_${motor.id}`);
      const impulseVolume = parseFloat(impulseVolumeStr);

      if (isNaN(impulseVolume)) {
        console.error("Volume par impulsion invalide:", impulseVolumeStr);
        return;
      }

      responseData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      const months = [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
        "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
      ];

      // Fonction pour formater le timestamp selon la granularité
      const formatTime = timestamp => {
        if (granularity === 'day') {
          return moment(timestamp).format('HH:mm');  // Afficher seulement l'heure pour la granularité "jour"
        } else if (granularity === 'month') {
          return `Semaine ${moment(timestamp).isoWeek()}`;  // Afficher le numéro de la semaine pour la granularité "mois"
        } else if (granularity === 'year') {
          const monthIndex = moment(timestamp).month();  // Obtenir l'index du mois (0-11)
          return `${months[monthIndex]}`;  // Afficher le mois et l'année
        } else {
          return moment(timestamp).format('DD/MM/YYYY');  // Format par défaut
        }
      };

      // Regrouper les données en fonction de la granularité
      const aggregateData = (data, granularity) => { 
        const groupedData = data.reduce((acc, item) => {
          let key;
          if (granularity === 'week') {
            key = moment(item.timestamp).startOf('day').format('DD/MM/YYYY');  // Un point par jour
          } else if (granularity === 'month') {
            key = `Semaine ${moment(item.timestamp).isoWeek()}`;  // Un point par semaine avec numéro de semaine
          } else if (granularity === 'year') {
            const monthIndex = moment(item.timestamp).month();  // Obtenir l'index du mois (0-11)
            key = `${months[monthIndex]}`;  // Un point par mois avec nom du mois en français
          } else {
            key = formatTime(item.timestamp);  // Pas de regroupement pour "jour"
          }

          if (!acc[key]) {
            acc[key] = { name: key, Consommation: 0, Debit: 0, count: 0 };
          }

          const volume = item.impulsion * impulseVolume;
          acc[key].Consommation += volume;  // Additionner la consommation
          acc[key].Debit += volume;  // Additionner pour le débit
          acc[key].count += 1;

          return acc;
        }, {});

        // Calcul de la moyenne pour le débit et arrondi à l'entier
        return Object.values(groupedData).map(item => ({
          ...item,
          Debit: item.count > 0 ? Math.round(item.Debit / item.count) : 0,  // Moyenne du débit arrondie
        }));
      };

      // Gestion de la granularité "jour" avec accumulation de la consommation
      if (granularity === 'day') {
        let accumulatedConsumption = 0;
        const dataForCharts = responseData.map(item => {
          const volume = item.impulsion * impulseVolume;
          accumulatedConsumption += volume; // Cumul de la consommation

          return {
            name: formatTime(item.timestamp),  // Utiliser le format adapté (heure pour "jour")
            Consommation: accumulatedConsumption,  // Cumulatif
            Debit: volume,  // Débit pour chaque trame
          };
        });

        setData2(dataForCharts);  // Consommation
        setData3(dataForCharts);  // Débit
      } else {
        // Si la granularité est semaine/mois/année
        const aggregatedData = aggregateData(responseData, granularity);
        setData2(aggregatedData);  // Consommation agrégée
        setData3(aggregatedData);  // Débit agrégé
      }

    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  }, [motor.id, user.token, granularity]);  // Dépendances de useCallback

  // Fonction appelée lors du clic sur le bouton Rechercher
  const handleSearchClick = useCallback(() => {
    const debut = moment(startDate).format('YYYY-MM-DD');
    const fin = moment(endDate).format('YYYY-MM-DD');
    fetchData(debut, fin);
  }, [fetchData, startDate, endDate]);  // Dépendances de useCallback
  
  // useEffect pour récupérer les données lors de l'ouverture de la page
  useEffect(() => {
    handleSearchClick();
  }, [handleSearchClick]);
  
  

  // const generateData = () => {
  //   const generatedData = [];
  //   const generatedDebitData = [];
  //   let totalPoints;
  //   let formatLabel;
  
  //   switch (granularity) {
  //     case 'day':
  //       totalPoints = 24 * 4; // 96 points pour un intervalle de 15 minutes sur une journée
  //       formatLabel = i => `${String(i / 4 | 0).padStart(2, '0')}:${String((i % 4) * 15).padStart(2, '0')}`;
  //       break;
  //     case 'week':
  //       totalPoints = 7 * 24; // 168 points pour un intervalle de 1 heure sur une semaine
  //       formatLabel = i => `${String(i % 24).padStart(2, '0')}h`;
  //       break;
  //     case 'month':
  //       totalPoints = 4; // 4 points pour un intervalle de 1 semaine sur un mois
  //       formatLabel = i => `Semaine ${i + 1}`;
  //       break;
  //     case 'year':
  //       totalPoints = 12; // 12 points pour un intervalle de 1 mois sur une année
  //       formatLabel = i => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][i];
  //       break;
  //     default:
  //       totalPoints = 96; // Par défaut, journée si la granularité n'est pas reconnue
  //       formatLabel = i => `${String(i / 4 | 0).padStart(2, '0')}:${String((i % 4) * 15).padStart(2, '0')}`;
  //   }
  
  //   for (let i = 0; i < totalPoints; i++) {
  //     generatedData.push({
  //       name: formatLabel(i),
  //       Consommation: Math.floor(Math.random() * 100) + 1
  //     });
  
  //     generatedDebitData.push({
  //       name: formatLabel(i),
  //       Debit: Math.floor(Math.random() * 100) + 1
  //     });
  //   }
  
  //   // Alertes fictives pour la consommation
  //   const alertesFictives = [
  //     { name: '15/01/2023', Consommation: 115 },
  //     { name: '10/03/2023', Consommation: 145 },
  //     { name: '22/06/2023', Consommation: 130 },
  //     { name: '17/09/2023', Consommation: 160 },
  //     { name: '05/12/2023', Consommation: 110 },
  //     { name: '20/02/2024', Consommation: 180 },
  //     { name: '15/05/2024', Consommation: 90 },
  //     { name: '30/07/2024', Consommation: 200 },
  //     { name: '12/08/2024', Consommation: 125 },
  //     { name: '01/09/2024', Consommation: 140 },
  //   ];
  
  //   // Mise à jour des états pour consommation et débit
  //   setData(alertesFictives); // Données fictives d'alertes pour la consommation
  //   setData2(generatedData); // Données générées de consommation
  //   setData3(generatedDebitData); // Données générées de débit
  // };

  const getChartTitle = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Consommation du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Consommation de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Consommation du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Consommation de l'année ${year} - ${motor.name}`;
        default:
            return `Consommation - ${motor.name}`;
    }
  };

  const getChartTitlePDF = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Rapport du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Rapport de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Rapport du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Rapport de l'année ${year} - ${motor.name}`;
        default:
            return `Rapport - ${motor.name}`;
    }
  };

  const getChartTitleExcel = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Tableur du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Tableur de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Tableur du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Tableur de l'année ${year} - ${motor.name}`;
        default:
            return `Tableur - ${motor.name}`;
    }
  };

  const getSecondChartTitle = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Débit du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Débit de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Débit du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Débit de l'année ${year} - ${motor.name}`;
        default:
            return `Débit - ${motor.name}`;
    }
};


  const exportPDF = () => {
    const chartElement1 = chartContainerRef.current; // Premier graphique (consommation)
    const chartElement2 = secondChartContainerRef.current; // Second graphique (débit)

    html2canvas(chartElement1).then(canvas1 => {
        const imgData1 = canvas1.toDataURL('image/png');

        html2canvas(chartElement2).then(canvas2 => {
            const imgData2 = canvas2.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.width;
            const pageHeight = pdf.internal.pageSize.height;

            // PAGE 1 - Header et graphiques
            // Ajouter le logo
            const logoUrl = logoAiga;
            pdf.addImage(logoUrl, 'PNG', 10, 10, 50, 20);

            // Ajouter le titre du rapport
            pdf.setFontSize(20);
            pdf.setFont('Helvetica', 'bold');
            pdf.text('Rapport de Consommation', pageWidth / 2 + 6, 25, { align: 'center' });

            // Ajouter la date
            pdf.setFontSize(12);
            pdf.setFont('Helvetica', 'normal');
            const dateStr = new Date().toLocaleDateString('fr-FR');
            pdf.text(`Date : ${dateStr}`, pageWidth - 10, 25, { align: 'right' });

            // Ajouter le premier graphique (consommation)
            pdf.addImage(imgData1, 'PNG', 10, 50, pageWidth - 20, 100);

            // Ajouter une bordure autour du premier graphique
            pdf.setDrawColor(0, 0, 0);
            pdf.rect(10, 52, pageWidth - 20, 100);

            // Ajouter le second graphique (débit)
            pdf.addImage(imgData2, 'PNG', 10, 160, pageWidth - 20, 100);

            // Ajouter une bordure autour du second graphique
            pdf.setDrawColor(0, 0, 0);
            pdf.rect(10, 162, pageWidth - 20, 100);

            // Passer à la page suivante
            pdf.addPage();

            // PAGE 2 - Informations et alertes
            // Ajouter le débit seuil et les informations
            pdf.setFontSize(16);
            pdf.setFont('Helvetica', 'bold');
            pdf.text('Informations sur le volume', 10, 20);
            
            pdf.setFontSize(14);
            pdf.setFont('Helvetica', 'normal');
            pdf.text(`Seuil alerte : ${debitSeuil} L/jour`, 10, 35);

            // Ajouter une ligne de séparation avant les alertes
            pdf.setLineWidth(0.5);
            pdf.line(10, 45, pageWidth - 10, 45);

            // Ajouter la section des alertes
            pdf.setFontSize(16);
            pdf.setFont('Helvetica', 'bold');
            pdf.text('Alertes', 10, 55);

            pdf.setFontSize(14);
            pdf.setFont('Helvetica', 'normal');

            if (alertes.length > 0) {
                let yPosition = 70;
                const lineHeight = 10;

                alertes.forEach(alerte => {
                    // Vérifier si la position actuelle dépasse la hauteur de la page
                    if (yPosition + lineHeight > pageHeight - 20) {
                        pdf.addPage();
                        yPosition = 20; // Replacer la position en haut de la nouvelle page
                        pdf.setFontSize(16);
                        pdf.setFont('Helvetica', 'bold');
                        pdf.text('Alertes (suite)', 10, yPosition);
                        yPosition += 15; // Ajouter un espace sous le titre
                        pdf.setFontSize(14);
                        pdf.setFont('Helvetica', 'normal');
                    }

                    // Ajouter le texte de l'alerte
                    pdf.text(`Date: ${alerte.date} - Volume dépassé: ${alerte.debit} L`, 10, yPosition);
                    yPosition += lineHeight;
                });
            } else {
                pdf.text('Aucune alerte enregistrée.', 10, 70);
            }

            // Enregistrer le PDF
            pdf.save(`${getChartTitlePDF()}.pdf`);
        });
    });
};

const exportExcel = () => {
  let firstColumnHeader;
  
  switch (granularity) {
    case 'day':
    case 'week':
      firstColumnHeader = 'Heure';
      break;
    case 'month':
      firstColumnHeader = 'Semaine';
      break;
    case 'year':
      firstColumnHeader = 'Mois';
      break;
    default:
      firstColumnHeader = 'Nom';
  }

  // Titre pour la première et deuxième feuille
  const sheetTitle = getChartTitleExcel(); // Titre du graphique ou du fichier

  // Première feuille pour les données de consommation avec un titre et une ligne vide
  const consommationData = [
    [sheetTitle],    // Ligne de titre
    [],              // Ligne vide pour espace
    [firstColumnHeader, 'Consommation (m³)'], // En-têtes de colonnes
    ...data2.map(item => [item.name, item.Consommation])
  ];

  // Deuxième feuille pour les données de débit avec un titre et une ligne vide
  const debitData = [
    [sheetTitle],    // Ligne de titre
    [],              // Ligne vide pour espace
    [firstColumnHeader, 'Débit (m³/s)'], // En-têtes de colonnes
    ...data3.map(item => [item.name, item.Debit])
  ];

  // Créer le classeur Excel
  const workbook = XLSX.utils.book_new();

  // Ajouter la feuille pour la consommation
  const consommationSheet = XLSX.utils.aoa_to_sheet(consommationData);
  XLSX.utils.book_append_sheet(workbook, consommationSheet, 'Consommation');

  // Ajouter la feuille pour le débit
  const debitSheet = XLSX.utils.aoa_to_sheet(debitData);
  XLSX.utils.book_append_sheet(workbook, debitSheet, 'Débit');

  // Exporter le fichier Excel
  XLSX.writeFile(workbook, `${getChartTitleExcel()}.xlsx`);
};

const handleCancelClick = () => {
  setIsEditing(false);
  setNewMotorName(motor.name); // Réinitialiser si annulation
};

const handleSaveClick = async () => {
  try {
    const response = await axios.post(`${API_URL}/boitier/nomboitier`, {
      id_boitier: motor.id,
      nom_boitier: newMotorName
    }, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });
    console.log('Boîtier modifié:', response.data);

    // Mettre à jour le state et le localStorage
    setMotor(prevMotor => ({ ...prevMotor, name: newMotorName }));
    localStorage.setItem('boitierName', newMotorName);
    setIsEditing(false);
  } catch (error) {
    console.error('Erreur lors de la modification du boîtier:', error);
  }
};

// Au chargement du composant, on vérifie le localStorage
useEffect(() => {
  const storedMotorName = localStorage.getItem('boitierName');
  if (storedMotorName) {
    setMotor(prevMotor => ({ ...prevMotor, name: storedMotorName }));
  }
  setNewMotorName(storedMotorName);
}, []);


return (
  <div className="min-h-screen flex flex-col bg-gray-100 text-gray-900">
    <Header user={user} />
    <div className="flex-grow flex items-center justify-center py-12">
        <div className="max-w-4xl w-full bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-semibold text-center mb-8">
            {isEditing ? (
              <div className="flex flex-col items-center justify-center space-y-4">
              <input
                type="text"
                value={newMotorName}
                onChange={(e) => setNewMotorName(e.target.value)}
                className="border border-gray-300 p-3 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none w-96"
                placeholder="Nom du moteur"
              />
              <div className="flex space-x-4">
                <button
                  onClick={handleSaveClick}
                  className="bg-gradient-to-r from-green-400 to-green-600 text-white px-5 py-2 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
                >
                  Valider
                </button>
                <button
                  onClick={handleCancelClick}
                  className="bg-gradient-to-r from-red-400 to-red-600 text-white px-5 py-2 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
                >
                  Annuler
                </button>
              </div>
            </div>            
            ) : (
              <div className="flex items-center justify-center space-x-4">
                <span>{motor.name}</span>
                <button onClick={() => setIsEditing(true)} className="ml-2">
                  <FaEdit className="text-black-500 hover:text-blue-700" />
                </button>
              </div>
            )}
          </h1>
          <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
            <h2 className="text-center text-xl lg:text-2xl font-semibold mb-6 text-gray-800">
              Informations du Compteur
            </h2>
            <p className="text-lg font-medium text-gray-700 mb-4">
              Client: <span className="font-bold text-blue-600">{motor.clientName || "Pas encore de client"}</span>
            </p>
            <p className="text-lg font-medium text-gray-700">
              Localisation: <span className="font-bold text-blue-600">{motor.location || "Pas encore de localisation"}</span>
            </p>
            {!motor.clientName || !motor.location ? (
              <AddClientLocation
                motor={motor}
                token={user.token}
              />
            ) : (
              <p>Client et localisation déjà définis.</p>
            )}
          </div>
          <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
      <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Paramètres du Boîtier</h2>
      <div className="mb-6">
        <label htmlFor="impulseVolume" className="block text-lg font-medium text-gray-700 mb-2">
          Volume par Impulsion (L) :
        </label>
        <input
          id="impulseVolume"
          type="number"
          className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Litres par impulsion"
          value={impulseVolume}
          onChange={handleVolumeChange}
        />
      </div>
    </div>
        <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
          <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Alerte de Dépassement</h2>
          <div className="mb-6">
            <label htmlFor="debitSeuil" className="block text-lg font-medium text-gray-700 mb-2">
              Définir Volume de Dépassement par Jour (L) :
            </label>
            <input
              id="debitSeuil"
              type="number"
              className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Litres par jour"
              value={debitSeuil}
              onChange={(e) => setDebitSeuil(e.target.value)}
            />
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4 text-gray-800">Historique des Alertes</h4>
            {alertes.length > 0 ? (
              <ul className="list-disc list-inside space-y-2">
                {alertes.map((alerte, index) => (
                  <li key={index} className="text-gray-700">
                    <span className="font-semibold text-blue-600">Date:</span> {alerte.date} <br />
                    <span className="font-semibold text-blue-600">Volume:</span> {alerte.debit} L
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">Aucune alerte enregistrée.</p>
            )}
          </div>
        </div>
        <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8">
          <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Paramètres de Filtrage</h2>
          <div className="bg-gray-50 p-6 rounded-lg shadow-inner space-y-6">
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-gray-700 text-sm lg:text-base font-medium mb-2">Date de début:</label>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  locale="fr"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 text-sm lg:text-base font-medium mb-2">Date de fin:</label>
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  locale="fr"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Granularité:</label>
              <select
                value={granularity}
                onChange={e => setGranularity(e.target.value)}
                className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {availableGranularities.includes('day') && (
                  <option value="day">Jour (10 min par point)</option>
                )}
                {availableGranularities.includes('week') && (
                  <option value="week">Semaine (1 jour par point)</option>
                )}
                {availableGranularities.includes('month') && (
                  <option value="month">Mois (1 semaine par point)</option>
                )}
                {availableGranularities.includes('year') && (
                  <option value="year">Année (1 mois par point)</option>
                )}
              </select>
            </div>
            <button
              onClick={handleSearchClick}
              className="bg-blue-600 text-white py-3 rounded-lg w-full hover:bg-blue-700 transition duration-300 font-semibold"
            >
              Rechercher
            </button>
          </div>
        </div>
        <div className="mt-10 flex flex-col items-center">
  {data2.length > 0 ? (
    <>
      {/* Graphique de consommation */}
      <div className="w-full max-w-3xl" ref={chartContainerRef}>
        <h3 className="text-xl lg:text-2xl font-medium text-center mb-4">{getChartTitle()}</h3>
        <div className="w-full h-auto">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data2} margin={{ top: 35, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={isMobile ? false : undefined} />
              <YAxis label={{ value: 'm³', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend />
              <Bar dataKey="Consommation" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Graphique de débit */}
      <div className="w-full max-w-3xl mt-10" ref={secondChartContainerRef}>
        <h3 className="text-xl lg:text-2xl font-medium text-center mb-4">{getSecondChartTitle()}</h3>
        <div className="w-full h-auto">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data3} margin={{ top: 35, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={isMobile ? false : undefined} />
              <YAxis label={{ value: 'Litre/h', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Debit" stroke="#82ca9d" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  ) : (
    <p className="text-center text-gray-600">Aucune donnée à afficher</p>
  )}
</div>
        <div className="mt-4 flex justify-center space-x-4">
          <button
            onClick={exportPDF}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
          >
            Exporter en PDF
          </button>
          <button
            onClick={exportExcel}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
          >
            Exporter en Excel
          </button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

}
