import React from "react";

export default function SearchCapteur({ searchTerm,handleSearch,handleAjouterCompteur,selectedCapteur }) {
  return (
    <div>
      <label className="flex flex-col">
        <input
          className="text-xs md:text-base lg:text-xl 3xl:text-2xl border-4 border-bleuCielSTB hover:border-bleuSTB rounded-3xl p-4 text-center mt-4 mb-4"
          type="text"
          placeholder="Entrez le nom du compteur ..."
          value={searchTerm}
          onChange={handleSearch}
        /></label>
      {selectedCapteur ? (
        <div className="text-center">
          <input type="submit" value="Ajouter le moteur" onClick={handleAjouterCompteur} className="bg-bleuSTB hover:bg-marronSTB text-white text-sm 3xl:text-xl border-none rounded-full p-2 w-60 mr-auto ml-auto cursor-pointer" />
          {/* {messageCapteur && <span className="bg-green-500 rounded-lg text-white mx-auto p-2 text-center my-4 text-xl flex items-center justify-center">{messageCapteur}</span>}
          {messageCapteurErreur && <span className="bg-error rounded-lg text-white text-xl mx-auto p-2 text-center mt-8 flex items-center justify-center">{messageCapteurErreur}</span>} */}
        </div>
      ) : searchTerm !== "" ? (
        <></>
      ) : (
        <></>
      )}
    </div>
  );
}
