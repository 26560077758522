import { Routes, Route, Navigate } from 'react-router-dom';
import React from "react";
import { useState } from "react";

import Login from "./Login/Login.js";
import Mentions from "./Mentions legales/Mentions.js";
import Home from './Home/Home.js';
import Admin from './Admin/Admin.js';
import Alerte from './Alerte/Alerte.js';
import DetailsCompteur from './DetailsCompteur/DetailsCompteur.js';

export const AppRoutes = () => {
  const [user, setUser] = useState(null);
  return (
    <Routes>
      <Route path="/" element={user ? <Navigate to="/home" /> : <Navigate to="/login" />}/>
      <Route path="/login" element={<Login setUser={setUser} />} />
      <Route path="/home" element={<Home setUser={setUser} />} />
      <Route path="/details" element={<DetailsCompteur setUser={setUser} />} />
      <Route path="/admin" element={<Admin setUser={setUser} />} />
      <Route path="/alerte" element={<Alerte setUser={setUser} />} />
      <Route path="/mentions-legales" element={<Mentions />} />
    </Routes>
  );
};