import React, { useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios';
import { API_URL } from "../constants";
import { useNavigate } from 'react-router-dom';

const AddClientLocation = ({ motor, token }) => {
  const [showAddClient, setShowAddClient] = useState(false);
  const [client, setClient] = useState("");
  const [localisation, setLocalisation] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [filteredClientList] = useState([]);
  const [selectedFromMap, setSelectedFromMap] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 46.603354, lng: 1.888334 });

  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCGIpqlgG8cTPyf4HA_mV0h9NKpSMF5BG8', // Remplacez par votre clé API Google Maps
  });

  const handleClientChange = (event) => {
    const searchText = event.target.value;
    setClient(searchText);
    // Logique de filtrage des clients
  };

  const handleLocalisationChange = async (e) => {
    const searchText = e.target.value;
    setLocalisation(searchText);

    if (!selectedFromMap && searchText.length >= 4) {
      const encodedText = encodeURIComponent(searchText).replace(/%20/g, '+');
      try {
        const response = await axios.get(
          `https://api-adresse.data.gouv.fr/search/?q=${encodedText}`
        );
        setSuggestions(response.data.features.map(feature => feature.properties));
      } catch (error) {
        console.error(error);
      }
    } else {
      setSelectedFromMap(false);
    }
  };

  const handleClientLocSelect = (selectedClient, selectedLocalisation) => {
    setClient(selectedClient);
    setLocalisation(selectedLocalisation);
  };
  
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    
    // Mise à jour du centre de la carte et des informations sur l'emplacement sélectionné
    setMapCenter({ lat, lng });
    setSelectedLocation({ lat, lng });
  
    // Reverse geocoding pour obtenir l'adresse à partir des coordonnées
    axios.get(`https://api-adresse.data.gouv.fr/reverse/?lat=${lat}&lon=${lng}`)
      .then(response => {
        const data = response.data;
        const address = {
          streetNumber: data.features[0].properties.housenumber || '',
          streetName: data.features[0].properties.street || '',
          postalCode: data.features[0].properties.postcode || '',
          city: data.features[0].properties.city || data.features[0].properties.town || data.features[0].properties.village || '',
        };
        const formattedAddress = `${address.streetNumber} ${address.streetName}, ${address.postalCode} ${address.city}`;
        setLocalisation(formattedAddress);
        setSelectedFromMap(true);
      })
      .catch(error => console.error(error));
  };

  const handleClientLocSubmit = async () => {
    try {
      const response = await axios.put(`${API_URL}/boitier/setclientloc`, {
        client: client,
        localisation: localisation,
        id_boitier: motor.id,
      }, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 401) {
        alert("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear(); 
        navigate("/login");
        return;
      }
      localStorage.setItem("motor", JSON.stringify(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!motor.client && !motor.localisation ? (
        !showAddClient ? (
          <button
            onClick={(event) => { setShowAddClient(true); event.stopPropagation(); }}
            className="bg-bleuSTB text-white text-xl 3xl:text-3xl mb-4 rounded-2xl px-4 py-4 mt-4 cursor-pointer hover:bg-marronSTB"
          >
            Ajouter un client et une localisation
          </button>
        ) : (
          <div>
            <input
              type="text"
              placeholder="Client"
              value={client}
              onChange={handleClientChange}
              className="text-base 3xl:text-xl border-2 border-orangeButton hover:border-bleuSTB w-50 sm:w-50 rounded-xl p-2 mr-2 mt-5"
              onClick={event => event.stopPropagation()}
            />
            <input
              type="text"
              placeholder="Localisation"
              value={localisation}
              onChange={handleLocalisationChange}
              className="text-base 3xl:text-xl border-2 border-orangeButton hover:border-bleuSTB w-96 sm:w-50 rounded-xl p-2 mr-5 mt-5"
              onClick={event => event.stopPropagation()}
            />
            <button
              onClick={() => setShowMap(true)}
              className="bg-bleuSTB text-white text-xs 3xl:text-2xl rounded-2xl px-4 py-4 mt-4 cursor-pointer hover:bg-marronSTB mr-5"
            >
              Choisir sur la carte
            </button>
            {showMap && isLoaded && (
              <GoogleMap
                mapContainerStyle={{ height: "400px", width: "100%" }}
                center={selectedLocation ? selectedLocation : mapCenter}  // Centrer sur l'endroit cliqué ou sur la France
                zoom={selectedLocation ? 15 : 6}  // Zoom plus important si une localisation est sélectionnée
                onClick={handleMapClick}
              >
                {selectedLocation && (
                  <Marker position={selectedLocation} />
                )}
              </GoogleMap>
            )}
            {localisation ? (
              <ul className="list-none p-0 m-0 mt-4 text-xl 3xl:text-3xl font-semibold">
                {suggestions.map((address, index) => (
                  <li
                    key={index}
                    onClick={() => setLocalisation(address.label)}
                    className="p-2 cursor-pointer bg-gray-200 rounded-md mb-4 text-gray-700 transition-colors duration-300 hover:bg-gray-300"
                  >
                    <span className="mr-5 text-2xl">{'→'}</span>{address.label}
                  </li>
                ))}
              </ul>
            ) : null}
            {client ? (
              <ul className="list-none p-0 m-0 mt-4 text-xl 3xl:text-3xl font-semibold">
                {filteredClientList.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleClientLocSelect(item.client, item.localisation)}
                    className="p-2 cursor-pointer bg-gray-200 rounded-md mb-4 text-gray-700 transition-colors duration-300 hover:bg-gray-300"
                  >
                    <span className="mr-5 text-2xl">{'→'}</span>{item.client} - {item.localisation}
                  </li>
                ))}
              </ul>
            ) : null}
            <button
              onClick={(event) => { handleClientLocSubmit(); event.stopPropagation(); }}
              className="bg-bleuSTB text-white text-xl 3xl:text-3xl rounded-2xl px-10 py-4 mt-4 cursor-pointer hover:bg-marronSTB"
            >
              Valider
            </button>
          </div>
        )
      ) : (
        <h4 className="pt-4 text-xl 3xl:text-3xl mb-4 underline font-bold">
          {!motor.client ? (
            <div className="">Client</div>
          ) : (
            <h4>
              <div className="">Client</div>
              <br />
              <span className="inline-block text-decoration-none font-light">{motor.client}</span>
            </h4>
          )}
          <br />
          {!motor.localisation ? (
            <div>Localisation</div>
          ) : (
            <h4 className="font-bold">
              <div>Localisation</div>
              <br />
              <span className="inline-block text-decoration-none font-light text-xl 3xl:text-3xl">{motor.localisation}</span>
            </h4>
          )}
        </h4>
      )}
    </>
  );
};

export default AddClientLocation;
