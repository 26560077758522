import React, { useState } from "react";
import { API_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function SetDefaultEmailFrequency({ token, motor }) {
  const [defaultEmailFrequency, setDefaultEmailFrequency] = useState(
    motor && motor.defaultEmailFrequency !== undefined ? motor.defaultEmailFrequency : "daily"
  );
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const showMessagePopup = (message) => {
    alert(message);
  };

  const handleFrequencyChange = (e) => {
    // Mettez à jour la valeur sélectionnée lorsque l'utilisateur choisit une option
    setDefaultEmailFrequency(e.target.value);
  };

  const handleSetFrequency = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/moteur/setDefaultEmailFrequency`,
        {
          defaultEmailFrequency: defaultEmailFrequency,
        },
        {
          headers: {
            mode: "cors",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 401) {
        showMessagePopup(
          "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
        );
        localStorage.clear();
        navigate.push("/login");
        return;
      }

      if (response.status === 200) {
        setShowSuccessMessage(true);

        // Masquer le message de succès après 3 secondes
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
      } else {
        console.error(
          "Erreur lors de la mise à jour de la fréquence d'envoi d'e-mails"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">
        Configurer la fréquence des mails par défaut :
      </h3>
      <select
        className="border p-2 rounded-md w-full mb-4"
        onChange={handleFrequencyChange}
        value={defaultEmailFrequency}
      >
        <option value="daily">Tous les jours</option>
        <option value="weekly">Toutes les semaines</option>
        <option value="biweekly">Toutes les deux semaines</option>
        <option value="monthly">Tous les mois</option>
      </select>
      <button
        className="bg-bleuSTB hover:bg-marronSTB text-white font-bold py-2 px-4 rounded"
        onClick={handleSetFrequency}
      >
        Définir Fréquence par Défaut
      </button>
      {showSuccessMessage && (
        <div className="text-green-600 dark:text-green-400 mt-4">
          Fréquence mise à jour avec succès !
        </div>
      )}
    </div>
  );
}
