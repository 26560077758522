import { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import axios from "axios";
import Error from "../Error/Error";
import ForgotPassword from "./ForgotPassword";
import useModal from "../Modal/useModal";
import { API_URL } from "../constants";

export default function LoginForm(props) {
  const [user, setUser] = useState({ mail: "", password: "" });
  const [stayConnected, setStayConnected] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { isShowing: isForgotPasswordModalShowing, toggle: toggleForgotPasswordModal } = useModal();

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleUser = (event) => setUser({ ...user, [event.target.name]: event.target.value });

  const handleCheckbox = () => setStayConnected(!stayConnected);

  const storeUserInLocalStorage = (userData) => {
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (user.mail !== "" && user.password !== "") {
      try {
        const response = await axios.post(`${API_URL}/user/login`, {
          email: user.mail,
          password: user.password,
        }, {
          headers: {
            mode: "cors",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          const json = response.data;
          props.setUser(json);

          if (stayConnected) {
            storeUserInLocalStorage(json);
          }
          navigate("/home");
        } else if (response.status === 409) {
          setError("Le nom d'utilisateur ou le mot de passe est incorrect.");
        } else {
          setError("Le nom d'utilisateur ou le mot de passe est incorrect.");
        }
      } catch (error) {
        console.error(error.message);
        setError("Le nom d'utilisateur ou le mot de passe est incorrect.");
      }
    } else {
      setError("Veuillez remplir tous les champs.");
    }
  };

  return (
    <div className="flex flex-col border-solid border-4 border-bleuSTB rounded-2xl text-center p-12 h-auto w-11/12 sm:w-1/2">
      <h2 className="-mt-4 -mb-4 text-bleuSTB font-bold text-3xl xl:text-5xl 3xl:text-6xl">Se connecter</h2>
      <form className="flex flex-col w-full m-auto mt-6" onSubmit={handleSubmit}>
        <label className="flex flex-col text-center sm:text-center sm:mb-2 sm:-mt-1 sm:text-sm xl:text-left xl:text-3xl 3xl:text-5xl mb-4">
          E-Mail
          <input
            type="mail"
            name="mail"
            placeholder="E-Mail"
            onChange={handleUser}
            value={user.mail}
            className="text-xs border-solid border-2 border-bleuCielSTB hover:border-bleuSTB rounded-2xl p-1 xl:p-5 text-center mt-2 xl:text-2xl 3xl:text-4xl"
          />
        </label>

        <label className="flex flex-col text-center sm:text-center sm:mb-2 sm:-mt-1 sm:text-sm xl:text-left xl:text-3xl 3xl:text-5xl mb-4">
          Mot de passe
          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            onChange={handleUser}
            value={user.password}
            className="text-xs border-solid border-2 border-bleuCielSTB hover:border-bleuSTB rounded-2xl p-1 xl:p-5 text-center mt-2 xl:text-2xl 3xl:text-4xl"
          />
        </label>

        <label className="block text-center text-xs sm:text-center sm:mb-2 sm:-mt-1 sm:text-base xl:text-left xl:text-3xl 3xl:text-4xl mb-6 xl:mt-2">
          Rester connecté
          <input
            type="checkbox"
            name="checkbox"
            onChange={handleCheckbox}
            checked={stayConnected}
            className="ml-4"
          />
          <span className="inline-block w-full float-none mt-10 text-bleuSTB text-center sm:-mb-1 xl:text-2xl 3xl:text-4xl cursor-pointer xl:w-fit xl:float-right xl:mt-0 hover:text-marronSTB hover:underline" onClick={toggleForgotPasswordModal}>
            Mot de passe oublié
          </span>
        </label>

        <input type="submit" value="Se Connecter" className="submitButton bg-bleuSTB hover:bg-marronSTB text-white text-xs sm:text-sm 3xl:text-4xl py-2 px-4 rounded-full xl:w-1/3 sm:w-32 mx-auto mt-4 mb-1 cursor-pointer" />

        {error && <Error message={error} />}
      </form>

      <ForgotPassword
        isShowing={isForgotPasswordModalShowing}
        hide={toggleForgotPasswordModal}
      />
    </div>
  );
}
