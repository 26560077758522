import Modal from "../Modal/modal";
import { useState, useEffect } from "react";
import { API_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const navigate = useNavigate();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const clearMessages = () => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [message]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put(`${API_URL}/user/resetpassword`, {
        email: email,
      }, {
        headers: {
          mode: "cors",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear();
        navigate.push("/login");
        return;
      }

      const data = response.data;

      if (response.status === 201) {
        setMessage(data.message);
        setMessageType("success");
      } else {
        setMessage(data.error);
        setMessageType("error");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Modal isShowing={props.isShowing} hide={props.hide} title="Mot de passe oublié">
      <form onSubmit={handleSubmit} className="flex flex-col w-full m-auto">
        <label className="flex flex-column text-xs sm:text-base 3xl:text-4xl text-left mb-1 mt-4 items-center">
          Adresse e-mail :
          <input type="email" value={email} onChange={handleEmailChange} className="text-xs border-solid border-2 border-loginBordure rounded-2xl p-1 xl:p-2 text-center 3xl:text-4xl ml-5 w-2/4" />
        </label>
        <input type="submit" value="Réinitialiser le mot de passe" className="submitButton bg-orange-500 hover:bg-orange-600 text-white 3xl:text-4xl text-xs py-2 px-4 rounded-2xl mx-auto mt-5 mb-1 cursor-pointer" />
        {message && (
          <p className={`message${messageType === "success" ? "Success" : "Erreur"}`}>
            {message}
          </p>
        )}
      </form>
    </Modal>
  );
}
