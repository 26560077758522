import React from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Alerte(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <Header user={user} />
      <div className="flex-grow flex items-center justify-center">
        <div className="max-w-4xl w-full p-6">
          <h1 className="text-5xl font-extrabold text-center mb-12 text-gray-800">Page paramétrage alerte</h1>
          </div>
          </div>
      <Footer />
    </div>
  );
}
