import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function ChangePasswordForm({ id, token }) {
  const [newuser, setNewUser] = useState({ mail: "", mdp: "" });
  const [messagePassword, setMessagePassword] = useState("");
  const navigate = useNavigate();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const clearMessages = () => {
    setTimeout(() => {
      setMessagePassword("");
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [messagePassword]);

  const handleChange2 = (event) => {
    setNewUser({
      ...newuser,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit3 = (event) => {
    event.preventDefault();

    // Vérifier la longueur du mot de passe
    if (newuser.mdp.length < 8) {
      setMessagePassword("ERREUR : Le mot de passe doit contenir au moins 8 caractères.");
      return; // Arrêtez l'exécution si le mot de passe est trop court
    }

    axios.post(`${API_URL}/user/changemdpAdmin`, {
      id: id,
      password: newuser.mdp,
    }, {
      headers: {
        mode: "cors",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      if (res.status === 401) {
        showMessagePopup(
          "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
        );
        localStorage.clear();
        navigate.push("/login");
      } else {
        return res.data;
      }
    })
    .then(
      (result) => {
        setMessagePassword(result.message);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  return (
    <form onSubmit={handleSubmit3} className="form">
      <label className="flex flex-col">
        <input
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-bleuCielSTB hover:border-bleuSTB rounded-3xl p-4 text-center mt-4 mb-4"
          onChange={handleChange2}
          value={newuser.mdp}
          placeholder="Nouveau mot de passe"
          name="mdp"
          type="password"
        />
      </label>
      <div className="text-center">
        <input
          type="submit"
          value="Changer le mot de passe"
          className="bg-bleuSTB hover:bg-marronSTB text-white text-sm 3xl:text-xl border-none rounded-full p-2 w-11/12 lg:w-60 mr-auto ml-auto cursor-pointer"
        />
        {messagePassword && (
          <span
            id="message"
            className="bg-green-500 rounded-lg text-white mx-auto p-2 text-center my-4 text-xl flex items-center justify-center"
          >
            {messagePassword}
          </span>
        )}
      </div>
    </form>
  );
}
